const URL = {
    merchant: {
        security: '/merchant/security',
        referral: '/merchant/referral',
        webhook: '/merchant/webhook',
        invoices: {
            list: '/merchant/invoices-list',
            add: '/merchant/invoices-create',
            detail: '/merchant/invoice/'
        },
        fee: '/fees'
    },
    public: {
        invoice: {
            share: '/invoice/'
        }
    }
};

export default URL;
