/**
 * axios setup to use mock service
 */

import axios from 'axios';

const axiosServices = axios.create({
    baseURL: 'https://wallet-api-internal.xaigate.com/'
});

axiosServices.defaults.headers.common['App-Platform'] = 'WebApp';
// interceptor for http
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => {
        Promise.reject((error.response && error.response.data) || 'Wrong Services');

        // dispatch(
        //     openSnackbar({
        //         open: true,
        //         message: error.response,
        //         variant: 'alert',
        //         alert: {
        //             color: 'error'
        //         },
        //         close: true,
        //         anchorOrigin: {
        //             vertical: 'bottom',
        //             horizontal: 'right'
        //         }
        //     })
        // );
    }
);

export default axiosServices;
