import { lazy } from 'react';

// project imports
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';
import URL from 'utils/url-page';

// login routing
const PageInvoiceShare = Loadable(lazy(() => import('views/pages/public/invoice-share')));

// ==============================|| AUTH ROUTING ||============================== //

const PublicRoutes = {
    element: <MinimalLayout />,
    children: [
        {
            path: `${URL.public.invoice.share}:invoiceId`,
            element: <PageInvoiceShare />
        }
    ]
};

export default PublicRoutes;
