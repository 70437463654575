// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconWallet,
    IconHome,
    IconUsers,
    IconFileInvoice,
    IconShieldLock,
    IconArrowsLeftRight,
    IconTool
} from '@tabler/icons';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { SHOW_MERCHANT } from 'constant/auth';
import showMenu from 'constant/role';
import URL from 'utils/url-page';
import Groups2OutlinedIcon from '@mui/icons-material/Groups';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CalculateIcon from '@mui/icons-material/Calculate';

// constant
const icons = {
    IconHome,
    IconWallet,
    IconUsers,
    IconFileInvoice,
    IconShieldLock,
    IconArrowsLeftRight,
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconTool,
    IconDocs: InsertDriveFileIcon,
    IconReferral: Groups2OutlinedIcon,
    IconWebhook: LocationSearchingIcon,
    IconInvoices: ReceiptLongIcon,
    IconFees: CalculateIcon
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const Merchant = (role: any) => ({
    hidden: showMenu(role, SHOW_MERCHANT),
    id: 'merchant',
    type: 'group',
    children: [
        // {
        //     id: 'Wallets',
        //     title: <FormattedMessage id="wallets" />,
        //     type: 'item',
        //     url: '/merchant/wallet',
        //     icon: icons.IconWallet,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'dashboard',
        //     title: <FormattedMessage id="dashboard" />,
        //     type: 'item',
        //     url: '/merchant/dasboard',
        //     icon: icons.IconHome,
        //     breadcrumbs: false
        // },
        {
            id: 'Referral',
            title: <FormattedMessage id="referral" />,
            type: 'item',
            url: URL.merchant.referral,
            icon: icons.IconReferral,
            breadcrumbs: false
        },
        {
            id: 'Invoices',
            title: <FormattedMessage id="invoices-text" />,
            type: 'item',
            url: URL.merchant.invoices.list,
            icon: icons.IconInvoices,
            breadcrumbs: false
        },

        {
            id: 'Credential',
            title: <FormattedMessage id="credential" />,
            type: 'item',
            url: '/merchant/credential',
            icon: icons.IconUsers,
            breadcrumbs: false
        },

        {
            id: 'Transactions',
            title: <FormattedMessage id="transactions" />,
            type: 'item',
            url: '/merchant/transaction',
            icon: icons.IconArrowsLeftRight,
            breadcrumbs: false
        },
        {
            id: 'Security',
            title: <FormattedMessage id="security" />,
            type: 'item',
            url: URL.merchant.security,
            icon: icons.IconShieldLock,
            breadcrumbs: false
        },
        {
            id: 'Logs',
            title: <FormattedMessage id="logs" />,
            type: 'item',
            url: '/merchant/logs',
            icon: icons.IconFileInvoice,
            breadcrumbs: false
        },
        {
            id: 'configure',
            title: <FormattedMessage id="configure" />,
            type: 'item',
            url: '/merchant/config',
            icon: icons.IconTool,
            breadcrumbs: false
        },
        {
            id: 'Webhook',
            title: <FormattedMessage id="webhook-text" />,
            type: 'item',
            url: URL.merchant.webhook,
            icon: icons.IconWebhook,
            breadcrumbs: false
        },
        {
            id: 'Calculator',
            title: <FormattedMessage id="fees-calculator-text" />,
            type: 'item',
            url: URL.merchant.fee,
            icon: icons.IconFees,
            breadcrumbs: false
        },
        {
            id: 'docs',
            title: <FormattedMessage id="docs" />,
            type: 'item',
            url: '/docs',
            icon: icons.IconDocs,
            breadcrumbs: false
        }
    ]
});

export default Merchant;
